* {
  box-sizing: border-box;
}

.bikfy-color {
  color: #5ec6d5;
}

.global {
  &.img {
    display: block;
    max-width: 100%;
  }
}
